import { z } from 'zod';
import type { EnvConfig } from './config';

const generalSchema = z.object({
  NEXT_PUBLIC_APP_BRAND: z.string(),
  NEXT_PUBLIC_APP_MARKET: z.string(),
  NEXT_PUBLIC_APP_LOCALE: z.string(),
  NEXT_PUBLIC_APP_VERSION: z.string().optional(),
  NEXT_PUBLIC_APP_BASE_URL: z.string().optional(),
  NEXT_PUBLIC_APP_PROTOCOL: z.string().optional(),
  NEXT_PUBLIC_APP_LANGUAGE: z.string(),
  NEXT_PUBLIC_APP_BRAND_CODE: z.string(),
  NEXT_PUBLIC_ENVIRONMENT: z.string(),
  NEXT_PUBLIC_NORCE_IMAGE_CDN: z.string(),
  NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO: z.string().optional(),
});

export type GeneralConfig = z.infer<typeof generalSchema>;

const config = ({ market, environment }: EnvConfig) => {
  const generalConfig: Partial<GeneralConfig> = {
    NEXT_PUBLIC_APP_BRAND: 'lanna',
    NEXT_PUBLIC_APP_MARKET: process.env.NEXT_PUBLIC_APP_MARKET,
    NEXT_PUBLIC_APP_LOCALE: process.env.NEXT_PUBLIC_APP_LOCALE,
    NEXT_PUBLIC_APP_BASE_URL: process.env.NEXT_PUBLIC_APP_BASE_URL,
    NEXT_PUBLIC_APP_PROTOCOL: process.env.NEXT_PUBLIC_APP_PROTOCOL,
    NEXT_PUBLIC_APP_LANGUAGE: process.env.NEXT_PUBLIC_APP_LANGUAGE,
    NEXT_PUBLIC_APP_BRAND_CODE: 'LM',
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  };

  switch (environment) {
    case 'local': {
      generalConfig.NEXT_PUBLIC_APP_PROTOCOL ??= 'http';
      break;
    }
    case 'dev': {
      generalConfig.NEXT_PUBLIC_APP_PROTOCOL ??= 'https';
      break;
    }
    case 'stage': {
      generalConfig.NEXT_PUBLIC_APP_PROTOCOL ??= 'https';
      break;
    }
    case 'preprod': {
      generalConfig.NEXT_PUBLIC_APP_PROTOCOL ??= 'http';
      break;
    }
    case 'prod': {
      generalConfig.NEXT_PUBLIC_APP_PROTOCOL ??= 'http';
      break;
    }
    default: {
      break;
    }
  }

  switch (market) {
    case 'se': {
      generalConfig.NEXT_PUBLIC_APP_MARKET ??= 'se';
      generalConfig.NEXT_PUBLIC_APP_LOCALE ??= 'sv-se';
      generalConfig.NEXT_PUBLIC_APP_LANGUAGE ??= 'sv';
      generalConfig.NEXT_PUBLIC_NORCE_IMAGE_CDN = 'https://cdn.lannamobler.se';
      switch (environment) {
        case 'local': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??= 'http://localhost:3000';
          break;
        }
        case 'dev': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??=
            'https://dev.lannamobler.se';
          break;
        }
        case 'stage': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??=
            'https://stage.lannamobler.se';
          break;
        }
        case 'preprod': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??=
            'https://preprod.lannamobler.se';
          break;
        }
        case 'prod': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??=
            'https://www.lannamobler.se';
          break;
        }
        default: {
          break;
        }
      }
      break;
    }
    case 'no': {
      generalConfig.NEXT_PUBLIC_APP_MARKET ??= 'no';
      generalConfig.NEXT_PUBLIC_APP_LOCALE ??= 'nb-no';
      generalConfig.NEXT_PUBLIC_APP_LANGUAGE ??= 'nb';
      generalConfig.NEXT_PUBLIC_NORCE_IMAGE_CDN = 'https://cdn.lanna.no';
      switch (environment) {
        case 'local': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??= 'http://localhost:3000';
          break;
        }
        case 'dev': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??= 'https://dev.lanna.no';
          break;
        }
        case 'stage': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??= 'https://stage.lanna.no';
          break;
        }
        case 'prod': {
          generalConfig.NEXT_PUBLIC_APP_BASE_URL ??= 'https://www.lanna.no';
          break;
        }
        default: {
          break;
        }
      }
      break;
    }
    default: {
      break;
    }
  }
  return generalSchema.parse(generalConfig);
};

export default config;
