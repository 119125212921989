import { z } from 'zod';
import type { EnvConfig } from './config';

const contentfulSchema = z.object({
  CONTENTFUL_SPACE_ID: z.string(),
  CONTENTFUL_HEADER_ID: z.string(),
  CONTENTFUL_SPACE_ENVIRONMENT: z.string().optional(),
});

export type ContentfulConfig = z.infer<typeof contentfulSchema>;

const config = ({ environment }: EnvConfig) => {
  const contentfulConfig: Partial<ContentfulConfig> = {
    CONTENTFUL_SPACE_ID: 'ye979dl3ln3a',
    CONTENTFUL_HEADER_ID: '61N6qveoM2VkJpz9UtU2IT',
    CONTENTFUL_SPACE_ENVIRONMENT: process.env.CONTENTFUL_SPACE_ENVIRONMENT,
  };

  if (!contentfulConfig.CONTENTFUL_SPACE_ENVIRONMENT) {
    switch (environment) {
      case 'dev':
      case 'local':
      case 'stage': {
        contentfulConfig.CONTENTFUL_SPACE_ENVIRONMENT ??= 'stage';
        break;
      }
      case 'preprod':
      case 'prod': {
        contentfulConfig.CONTENTFUL_SPACE_ENVIRONMENT ??= 'master';
        break;
      }
      default:
    }
  }
  return contentfulSchema.parse(contentfulConfig);
};

export default config;
