import { z } from 'zod';
import type { EnvConfig } from './config';

const datadogSchema = z.object({
  NEXT_PUBLIC_DATADOG_RUM: z.string().optional(),
  NEXT_PUBLIC_DATADOG_ENV: z.string(),
  NEXT_PUBLIC_DATADOG_SITE: z.string(),
  NEXT_PUBLIC_DATADOG_SERVICE: z.string(),
  NEXT_PUBLIC_DATADOG_SAMPLE_RATE: z.string(),
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: z.string().optional(),
  NEXT_PUBLIC_DATADOG_APPLICATION_ID: z.string().optional(),
});

export type DatadogConfig = z.infer<typeof datadogSchema>;

const config = ({ market, environment }: EnvConfig) => {
  const datadogConfig: Partial<DatadogConfig> = {
    NEXT_PUBLIC_DATADOG_SITE: 'datadoghq.eu',
    NEXT_PUBLIC_DATADOG_SAMPLE_RATE: '10',
    NEXT_PUBLIC_DATADOG_APPLICATION_ID: '23c8a9ea-8746-477a-a8fc-92d65b5f949c',
    NEXT_PUBLIC_DATADOG_RUM: process.env.NEXT_PUBLIC_DATADOG_RUM ?? 'false',
  };

  switch (environment) {
    case 'local':
    case 'dev': {
      datadogConfig.NEXT_PUBLIC_DATADOG_ENV = 'dev';
      break;
    }
    case 'stage': {
      datadogConfig.NEXT_PUBLIC_DATADOG_ENV = 'stage';
      break;
    }
    case 'preprod': {
      datadogConfig.NEXT_PUBLIC_DATADOG_ENV = 'pre-prod';
      break;
    }
    case 'prod': {
      datadogConfig.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN =
        'pub90df3d74fe60c7f86f720639ebacedd4';
      datadogConfig.NEXT_PUBLIC_DATADOG_ENV = 'prod';
      break;
    }
    default: {
      break;
    }
  }

  switch (market) {
    case 'no': {
      datadogConfig.NEXT_PUBLIC_DATADOG_SERVICE = 'lanna.no';
    }
    case 'se': {
      datadogConfig.NEXT_PUBLIC_DATADOG_SERVICE = 'lannamobler.se';
    }
    default: {
      break;
    }
  }
  return datadogSchema.parse(datadogConfig);
};

export default config;
