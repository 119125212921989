import { z } from 'zod';
import type { EnvConfig } from './config';

const voyadoSchema = z.object({
  VOYADO_SCRIPT_BUNDLE: z.string(),
});

export type VoyadoConfig = z.infer<typeof voyadoSchema>;

const config = ({ environment }: EnvConfig) => {
  const voyadoConfig: Partial<VoyadoConfig> = {};
  switch (environment) {
    case 'local':
    case 'dev':
    case 'stage': {
      voyadoConfig.VOYADO_SCRIPT_BUNDLE =
        'https://assets.voyado.com/jsfiles/analytics_0.1.7.staging.min.js';
      break;
    }
    case 'preprod':
    case 'prod': {
      voyadoConfig.VOYADO_SCRIPT_BUNDLE =
        'https://assets.voyado.com/jsfiles/analytics_0.1.7.min.js';
      break;
    }
    default: {
      break;
    }
  }
  return voyadoSchema.parse(voyadoConfig);
};

export default config;
