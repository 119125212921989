import { z } from 'zod';
import type { EnvConfig } from './config';

const cacheSchema = z.object({
  REDIS_URL: z.string().optional(),
  REDIS_CACHE: z.boolean(),
  REDIS_AVAILABLE: z.boolean(),
  CACHE_LOGGING: z.boolean(),
});

export type CacheConfig = z.infer<typeof cacheSchema>;

const config = ({ market, environment }: EnvConfig) => {
  const cacheConfig: Partial<CacheConfig> = {
    REDIS_URL: process.env.REDIS_URL,
    REDIS_CACHE: String(process.env.REDIS_CACHE) === 'true',
    REDIS_AVAILABLE: String(process.env.REDIS_AVAILABLE) === 'true',
    CACHE_LOGGING: String(process.env.CACHE_LOGGING) === 'true',
  };

  switch (environment) {
    case 'preprod': {
      cacheConfig.REDIS_CACHE = true;
      break;
    }
    case 'prod': {
      cacheConfig.REDIS_CACHE = true;
      break;
    }
    default:
      break;
  }
  return cacheSchema.parse(cacheConfig);
};

export default config;
