import { z } from 'zod';
import type { EnvConfig } from './config';

const gtmSchema = z.object({
  GTM_CONTAINER_ID: z.string().optional(),
});
export type GtmConfig = z.infer<typeof gtmSchema>;

const config = ({ environment }: EnvConfig) => {
  const gtmConfig: Partial<GtmConfig> = {
    GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID,
  };
  switch (environment) {
    case 'stage':
    case 'preprod': {
      gtmConfig.GTM_CONTAINER_ID ??= 'GTM-MWTVHQP2';
      break;
    }
    case 'prod': {
      gtmConfig.GTM_CONTAINER_ID ??= 'GTM-TW3S8GB';
      break;
    }
    default: {
      break;
    }
  }
  return gtmSchema.parse(gtmConfig);
};

export default config;
