import dotenv from 'dotenv';
import { z } from 'zod';
import cache, { type CacheConfig } from './cache';
import contentful, { type ContentfulConfig } from './contentful';
import datadog, { type DatadogConfig } from './datadog';
import features, { type FeaturesConfig } from './features';
import general, { type GeneralConfig } from './general';
import gtm, { type GtmConfig } from './gtm';
import searchEngine, { type SearchEngineConfig } from './search-engine';
import service, { type ServiceConfig } from './service';
import sitemap, { type SitemapConfig } from './sitemap';
import voyado, { type VoyadoConfig } from './voyado';
import walley, { type WalleyConfig } from './walley';

if (!process.env.NEXT_PUBLIC_ENVIRONMENT && typeof window === 'undefined') {
  const dotEnvConfig = dotenv.config();

  if (dotEnvConfig.error) {
    throw new Error('Could not load environment variables');
  }
}

const VALID_ENVIRONMENTS = [
  'local',
  'dev',
  'stage',
  'preprod',
  'prod',
] as const;
const EnvEnum = z.enum(VALID_ENVIRONMENTS);
const environment = EnvEnum.parse(process.env.NEXT_PUBLIC_ENVIRONMENT);
const VALID_APP_MARKETS = ['se', 'no'] as const;
const MarketEnum = z.enum(VALID_APP_MARKETS);
const market = MarketEnum.parse(
  process.env.NEXT_PUBLIC_APP_MARKET?.toLowerCase(),
);

type Envrionment = typeof VALID_ENVIRONMENTS[number];

type AppMarket = typeof VALID_APP_MARKETS[number];

export type EnvConfig = {
  environment: Envrionment;
  market: AppMarket;
};

/** CACHE */

const cacheConfig = cache({ market, environment });

/** CONTENTFUL */

const contentfulConfig = contentful({ market, environment });

/** DATADOG */

const datadogConfig = datadog({ market, environment });

/** GENERAL */

const generalConfig = general({ market, environment });

/** GTM */

const gtmConfig = gtm({ market, environment });

/** SEARCH ENGINE */

const searchEngineConfig = searchEngine({ market, environment });

/** SERVICE */

const serviceConfig = service({ market, environment });

/** SITEMAP */

const sitemapConfig = sitemap({ market, environment });

/** VOYADO */

const voyadoConfig = voyado({ market, environment });

/** WALLEY */

const walleyConfig = walley({ market, environment });

/** FEATURES */

const featuresConfig = features({ market, environment });

/** COMPILATION */

export type EnvironmentConfig = CacheConfig &
  ContentfulConfig &
  DatadogConfig &
  FeaturesConfig &
  GeneralConfig &
  GtmConfig &
  SearchEngineConfig &
  ServiceConfig &
  SitemapConfig &
  VoyadoConfig &
  WalleyConfig;

export const envConfig: EnvironmentConfig = {
  ...cacheConfig,
  ...contentfulConfig,
  ...datadogConfig,
  ...featuresConfig,
  ...generalConfig,
  ...gtmConfig,
  ...searchEngineConfig,
  ...serviceConfig,
  ...sitemapConfig,
  ...voyadoConfig,
  ...walleyConfig,
};
