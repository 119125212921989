import { z } from 'zod';
import type { EnvConfig } from './config';

const walleySchema = z.object({
  NEXT_PUBLIC_WALLEY_TERMS_URL: z.string(),
  NEXT_PUBLIC_WALLEY_RETURN_URL: z.string(),
  NEXT_PUBLIC_WALLEY_ENVIRONMENT: z.string(),
  NEXT_PUBLIC_WALLEY_PAYMENT_METHOD: z.string(),
  NEXT_PUBLIC_WALLEY_PAYMENT_SERVICE: z.string(),
  NEXT_PUBLIC_WALLEY_B2B_STORE_CODE: z.number(),
  NEXT_PUBLIC_WALLEY_B2C_STORE_CODE: z.number(),
  NEXT_PUBLIC_WALLEY_SCRIPT_SOURCE: z.string(),
});

export type WalleyConfig = z.infer<typeof walleySchema>;

const config = ({ market, environment }: EnvConfig) => {
  const walleyConfig: Partial<WalleyConfig> = {
    NEXT_PUBLIC_WALLEY_RETURN_URL:
      '/checkout/success?reference={checkout.publictoken}',
    NEXT_PUBLIC_WALLEY_PAYMENT_METHOD: '165',
    NEXT_PUBLIC_WALLEY_PAYMENT_SERVICE: 'Collector',
  };
  switch (market) {
    case 'se': {
      walleyConfig.NEXT_PUBLIC_WALLEY_TERMS_URL = '/kopvillkor';
      break;
    }
    case 'no': {
      walleyConfig.NEXT_PUBLIC_WALLEY_TERMS_URL = '/kjopsvilkar';
      break;
    }
    default: {
      break;
    }
  }

  switch (environment) {
    case 'local':
    case 'dev':
    case 'stage': {
      walleyConfig.NEXT_PUBLIC_WALLEY_SCRIPT_SOURCE =
        'https://api.uat.walleydev.com/walley-checkout-loader.js';
      walleyConfig.NEXT_PUBLIC_WALLEY_ENVIRONMENT = 'development';
      if (market === 'no') {
        walleyConfig.NEXT_PUBLIC_WALLEY_B2C_STORE_CODE = 6432;
        walleyConfig.NEXT_PUBLIC_WALLEY_B2B_STORE_CODE = 6433;
      } else if (market === 'se') {
        walleyConfig.NEXT_PUBLIC_WALLEY_B2C_STORE_CODE = 6428;
        walleyConfig.NEXT_PUBLIC_WALLEY_B2B_STORE_CODE = 6429;
      }
      break;
    }
    case 'preprod':
    case 'prod': {
      walleyConfig.NEXT_PUBLIC_WALLEY_SCRIPT_SOURCE =
        'https://api.walleypay.com/walley-checkout-loader.js';
      walleyConfig.NEXT_PUBLIC_WALLEY_ENVIRONMENT = 'production';
      if (market === 'no') {
        walleyConfig.NEXT_PUBLIC_WALLEY_B2C_STORE_CODE = 11454;
        walleyConfig.NEXT_PUBLIC_WALLEY_B2B_STORE_CODE = 11455;
      } else if (market === 'se') {
        walleyConfig.NEXT_PUBLIC_WALLEY_B2C_STORE_CODE = 11452;
        walleyConfig.NEXT_PUBLIC_WALLEY_B2B_STORE_CODE = 11453;
      }
      break;
    }
    default: {
      break;
    }
  }
  return walleySchema.parse(walleyConfig);
};

export default config;
