import { z } from 'zod';
import type { EnvConfig } from './config';

const searchEngineSchema = z.object({
  NEXT_PUBLIC_SEARCH_ENGINE_URL: z.string().optional(),
});
export type SearchEngineConfig = z.infer<typeof searchEngineSchema>;

const config = ({ market, environment }: EnvConfig) => {
  const searchEngineConfig: Partial<SearchEngineConfig> = {
    NEXT_PUBLIC_SEARCH_ENGINE_URL: process.env.NEXT_PUBLIC_SEARCH_ENGINE_URL,
  };
  switch (environment) {
    case 'local':
    case 'dev':
    case 'stage': {
      searchEngineConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL ??= `https://lannamobler-${market}-dev.54proxy.com`;
      break;
    }
    case 'preprod':
    case 'prod': {
      searchEngineConfig.NEXT_PUBLIC_SEARCH_ENGINE_URL ??= `https://lannamobler-${market}-prod.54proxy.com`;
      break;
    }
    default: {
      break;
    }
  }
  return searchEngineSchema.parse(searchEngineConfig);
};

export default config;
