import { z } from 'zod';
import type { EnvConfig } from './config';

const sitemapSchema = z.object({
  BUILD_APP_SEO: z.boolean(),
  SITEMAP_NAME: z.string(),
  SITEMAP_DOMAIN: z.string(),
  SITEMAP_PRODUCT_URL: z.string(),
  SITEMAP_BLOB_STORAGE: z.string(),
  SITEMAP_APP_ENVIRONMENT: z.string().optional(),
});

export type SitemapConfig = z.infer<typeof sitemapSchema>;

const config = ({ market, environment }: EnvConfig) => {
  const sitemapConfig: Partial<SitemapConfig> = {
    BUILD_APP_SEO: false,
    SITEMAP_NAME: 'lanna',
    SITEMAP_PRODUCT_URL:
      'https://nggfeedpublic{env}.blob.core.windows.net/productfeedcontainer/googlesitemaps_www.{domain}.{market}-{storage}-{language}.xml',
    SITEMAP_BLOB_STORAGE: '4f11811e-3cbd-4b38-a9bb-634e5f1dffb5',
  };
  switch (environment) {
    case 'prod': {
      sitemapConfig.BUILD_APP_SEO = true;
      break;
    }
    default: {
      break;
    }
  }

  switch (market) {
    case 'se': {
      sitemapConfig.SITEMAP_DOMAIN = 'lannamobler';
      break;
    }
    case 'no': {
      sitemapConfig.SITEMAP_DOMAIN = 'lanna';
      break;
    }
    default: {
      break;
    }
  }
  return sitemapSchema.parse(sitemapConfig);
};

export default config;
