import { z } from 'zod';
import type { EnvConfig } from './config';

const featuresSchema = z.object({
  NEXT_PUBLIC_FEATURES_CACHE_LOOP: z.string().optional(),
  NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY: z.string().optional(),
  NEXT_PUBLIC_FEATURES_CHECKOUT_MADE_TO_ORDER: z.string().optional(),
  NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_SEARCH: z.string().optional(),
  NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_PRODUCTS: z.string().optional(),
  NEXT_PUBLIC_FEATURES_MOBILE_NEW_CUSTOMER_DISCOUNT_POPUP: z
    .string()
    .optional(),
});

export type FeaturesConfig = z.infer<typeof featuresSchema>;

const config = ({ market, environment }: EnvConfig) => {
  const featuresConfig: Partial<FeaturesConfig> = {
    NEXT_PUBLIC_FEATURES_CACHE_LOOP:
      process.env.NEXT_PUBLIC_FEATURES_CACHE_LOOP ?? 'true',
    NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY:
      process.env.NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY ?? 'true',
    NEXT_PUBLIC_FEATURES_CHECKOUT_MADE_TO_ORDER:
      process.env.NEXT_PUBLIC_FEATURES_CHECKOUT_MADE_TO_ORDER ?? 'false',
    NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_SEARCH:
      process.env.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_SEARCH ?? 'true',
    NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_PRODUCTS:
      process.env.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_PRODUCTS ?? 'true',
    NEXT_PUBLIC_FEATURES_MOBILE_NEW_CUSTOMER_DISCOUNT_POPUP:
      process.env.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_PRODUCTS ?? 'true',
  };

  /** Add market/environment specific overrides here
    switch (market) {
      case 'se':
        featuresConfig.NEXT_PUBLIC_FEATURES_CACHE_LOOP = 'false';
        break;
      default:
        break;
    }
  */

  return featuresSchema.parse(featuresConfig);
};

export default config;
