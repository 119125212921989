import { z } from 'zod';
import type { EnvConfig } from './config';

const serviceSchema = z.object({
  SERVICE_USER: z.string(),
  NEXT_PUBLIC_SERVICE_ENDPOINT: z.string(),
  NEXT_PUBLIC_SERVICE_GRAPHQL: z.string(),
  REVALIDATION_APP_URL: z.string().optional(),
  REWRITE_ENDPOINT: z.string().optional(),
});
export type ServiceConfig = z.infer<typeof serviceSchema>;

const config = ({ market, environment }: EnvConfig) => {
  const serviceConfig: Partial<ServiceConfig> = {
    SERVICE_USER: process.env.SERVICE_USER,
    NEXT_PUBLIC_SERVICE_ENDPOINT: process.env.NEXT_PUBLIC_SERVICE_ENDPOINT,
    REWRITE_ENDPOINT: process.env.REWRITE_ENDPOINT,
  };
  switch (environment) {
    case 'local':
      serviceConfig.SERVICE_USER ??= 'Admin';
      serviceConfig.NEXT_PUBLIC_SERVICE_ENDPOINT ??=
        'http://localhost:4501/nglm-graph/v2';
      serviceConfig.REWRITE_ENDPOINT ??= 'localhost:3000';
      break;
    case 'dev': {
      serviceConfig.SERVICE_USER ??= 'nglm-graph';
      serviceConfig.NEXT_PUBLIC_SERVICE_ENDPOINT ??=
        'https://api2-stage.no-ga.com/nglm-graph/v2';
      serviceConfig.REWRITE_ENDPOINT ??=
        market === 'no' ? 'dev.lanna.no' : 'dev.lannamobler.se';
      break;
    }
    case 'stage': {
      serviceConfig.SERVICE_USER ??= 'nglm-graph';
      serviceConfig.NEXT_PUBLIC_SERVICE_ENDPOINT ??=
        'https://api2-stage.no-ga.com/nglm-graph/v2';
      serviceConfig.REWRITE_ENDPOINT ??=
        market === 'no' ? 'stage.lanna.no' : 'stage.lannamobler.se';
      break;
    }
    case 'preprod':
    case 'prod': {
      serviceConfig.SERVICE_USER ??= 'nglm-graph';
      serviceConfig.NEXT_PUBLIC_SERVICE_ENDPOINT ??=
        'https://api2.no-ga.com/nglm-graph/v2';
      serviceConfig.REVALIDATION_APP_URL =
        'http://cb1bf16e-f858-4cb9-8d91-25f4a2998927.k8s.civo.com';
      serviceConfig.REWRITE_ENDPOINT =
        market === 'no' ? 'www.lanna.no' : 'www.lannamobler.se';
      break;
    }
    default: {
      break;
    }
  }
  serviceConfig.NEXT_PUBLIC_SERVICE_GRAPHQL = `${serviceConfig.NEXT_PUBLIC_SERVICE_ENDPOINT}/graphql`;
  return serviceSchema.parse(serviceConfig);
};

export default config;
